<!--  -->
<template>
  <div class="wallpaperCon">
    <div class="wBox" ref="wBox" @mousemove="moduleAnimate($event)" @mouseleave="moduleAnimateReset($event)" :style="`transform: perspective(${perspective}px) rotateX(${rotateX}deg) rotateY(${rotateY}deg);`">
      <div class="move-wrapper overflow-hidden">
        <div class="move-circle" ref="moveCircle" :style="`transform: translate(${moveX}px, ${moveY}px);`"></div>
      </div>
      <div class="wallpaper">
        <ul class="wallpaper-tag-list">
          <li class="tag-item" :class="{ active: activeTagId == item.id }" v-for="item in tags" :key="item.id" @click="onTagClick(item.id)">{{ item.tag }}</li>
        </ul>
        <ul class="wallpaper-list">
          <li class="wallpaper-item" v-for="(item, index) in wallpaperListPage" :key="index" @click.stop="onWallpaperClick(item)">
            <div class="image-wrapper">
              <img :src="item.img_url" alt="" />
            </div>
            <div class="hoverShow">
              <div class="wallpaper-title">{{ item.paper_name }}</div>
              <download @download="onDownload" :channel="channel" class="down"></download>
            </div>
          </li>
        </ul>
        <div class="wallpaper-more" :class="{ 'is-baidu': isBaidu, 'is-360':is360, 'is-qihu': isQihu, 'is-qihu2': isQihu2 }" @click="onDownload" id="bd1">更多壁纸</div>
      </div>
    </div>

  </div>

</template>

<script>
import download from "./downLoadBtn.vue";
import { apiSelected } from "@/api/channel";
export default {
  name: "",
  components: {
    download,
  },
  props: {
    channel: null,
  },
  data() {
    return {
      perspective: 1600,
      rotateX: 0,
      rotateY: 0,
      moveX: -600,
      moveY: 0,
      activeTagId: 0,
      wallpaperListPage: null,
      tagSelectTime: false,
      tags: [
        { id: 0, tag: "精选" },
        { id: 12, tag: "ACG动漫" },
        { id: 7, tag: "遇见美景" },
        { id: 10, tag: "科幻探索" },
        { id: 5, tag: "幻想女友" },
        { id: 2, tag: "游戏风象" },
        { id: 14, tag: "学习圈子" },
        { id: 18, tag: "国漫天地" },
        { id: 17, tag: "磕糖专区" },
        { id: 19, tag: "军事体育" },
      ],
    };
  },
  computed: {
    isBaidu() {
      return this.channel == 20210426043;
    },
    is360() {
      return this.channel == 15170001125;
    },
    isQihu() {
      return this.channel == 20200707006;
    },
    isQihu2() {
      return this.channel == 2887211655;
    },
  },
  async created() {
    await this.getWallpaperList();
  },
  methods: {
    async getWallpaperList() {
      const { n: channel, name } = this.$route.query;
      const resp = await apiSelected({
        name,
        type: this.activeTagId == 0 ? 1 : 2,
        tag_id: this.activeTagId,
      });
      this.wallpaperListPage = resp.data;
    },
    onTagClick(id) {
      if(this.tagSelectTime) return
      this.activeTagId = id;
      this.tagSelectTime = true
      this.getWallpaperList();
      setTimeout(() => {
        this.tagSelectTime = false
      }, 1000);
    },
    onDownload() {
      this.$emit("download");
    },
    moduleAnimate(e) {
      var $moveCircle = this.$refs.moveCircle;
      var $wBox = this.$refs.wBox;
      var moveX = 0;
      var moveY = 0;
      var skewX = 0;
      var skewY = 0;
      moveX =
        e.clientX -
        $wBox.getBoundingClientRect().left -
        $moveCircle.clientWidth / 2;
      moveY =
        e.clientY -
        $wBox.getBoundingClientRect().top -
        $moveCircle.clientHeight / 2;
      skewX =
        ((e.clientY -
          $wBox.getBoundingClientRect().top -
          $wBox.clientHeight / 2) /
          $wBox.clientHeight) *
        2;
      skewY =
        -(
          (e.clientX -
            $wBox.getBoundingClientRect().left -
            $wBox.clientWidth / 2) /
          $wBox.clientWidth
        ) * 3;
      this.rotateX = skewX;
      this.rotateY = skewY;
      this.moveX = moveX;
      this.moveY = moveY;
      //   e.target.style.transform = `perspective(${elWidth}px) rotateX(${x}deg) rotateY(${y}deg) scale3d(1,1,1)`;
    },
    moduleAnimateReset(e) {
      this.rotateX = 0;
      this.rotateY = 0;
      this.moveX = -600;
      this.moveY = 0;
      // console.log("moduleAnimateReset");
      //   e.target.style.transform = "perspective(500px) rotateX(0) rotateY(0)";
    },
  },
};
</script>

<style lang='scss' scoped>
.wallpaperCon {
  width: 1600px;
  height: 968px;
  margin-top: 64px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  .wBox {
    width: 1600px;
    height: 968px;
    transition: transform 0.3s ease-out;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 20px 20px 20px 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
    // transform-style: preserve-3d;
  }
  .move-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 99;
    .move-circle {
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 1.2s;
      mix-blend-mode: soft-light;
      will-change: transform;
      pointer-events: none;
      opacity: 0;
      border-radius: 50%;
      background: #fb38fe;
      width: 256px;
      height: 256px;
      filter: blur(153px);
      opacity: 0.7;
    }
  }
}
.wallpaper {
  width: 1600px;
  height: 968px;
  // background: rgba(255, 255, 255, 0.06);
  // border-radius: 20px 20px 20px 20px;
  // border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 99;
  .wallpaper-tag-list {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 58px;
    .tag-item {
      padding: 0 14px;
      height: 40px;
      border-radius: 8px 8px 8px 8px;
      border: 2px solid rgba(255, 255, 255, 0.4);
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #ffffff;
      box-sizing: border-box;
      cursor: pointer;
      &.active {
        background: linear-gradient(90deg, #2142ff 0%, #3796fe 100%);
        font-weight: 700;
        color: #ffffff;
        border: none !important;
        cursor: default;
      }
      &:hover {
        border: 2px solid #fff;
      }
    }
  }
  .wallpaper-list {
    width: 1200px;
    margin: 0 auto;
    margin-top: 26px;
    display: flex;
    flex-flow: wrap;
    .wallpaper-item {
      width: 388px;
      height: 218px;
      border-radius: 10px;
      margin-right: 18px;
      margin-bottom: 18px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        .hoverShow {
          display: block;
        }
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      .image-wrapper {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .hoverShow {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        ::v-deep(.downLoadUrl) {
          width: 154px;
          height: 44px;
          font-size: 18px;
        }
        .down {
          position: absolute;
          z-index: 2;
          left: 118px;
          bottom: 28px;
        }
        .wallpaper-title {
          position: absolute;
          left: 8px;
          top: 8px;
          right: 8px;
          height: 24px;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          color: #ffffff;
          text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .wallpaper-more {
    width: 286px;
    height: 58px;
    margin: 0 auto;
    margin-top: 46px;
    background: url("../../assets/images/bd01/more.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: url("../../assets/images/bd01/more_h.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>