<template>
  <div class="footer">
    <div class="footerCenter">
      <div class="footerUrl">
        <ul class="flex-vw flex">
          <li
            title="copyright@2019 www.apumao.vip All Right Reserved 武汉阿朴猫网络科技有限公司版权所有"
            class="flex"
            style="margin:0 auto 18px;">
            <span>
                copyright@2019 www.apumao.vip All Right Reserved 武汉阿朴猫网络科技有限公司版权所有
            </span>
          </li>
        </ul>
        <ul class="flex-vw flex">
          <li title="鄂ICP备2023023531号-1" class="flex" style="padding-left:230px;">
            <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2023023531号-1</a>
          </li>
          <li title="中国互联网违法和不良信息举报中心" class="flex">
            <a href="https://www.12377.cn/" target="_blank">中国互联网违法和不良信息举报中心</a>
          </li>
          <li title="中国扫黄打非-网上举报" class="flex">
            <a href="https://www.shdf.gov.cn/shdf/channels/740.html" target="_blank">中国扫黄打非-网上举报</a>
          </li>
          <li title="12318全国文化市场举报网站" class="flex">
            <a href="https://jbts.mct.gov.cn/" target="_blank">12318全国文化市场举报网站</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: auto;
  background: #ebf4ff;
  position: relative;
  .footerCenter {
    width: 1070px;
    margin: 0 auto;
    height: 100%;
  }
  .footerUrl {
    width: 100%;
    padding: 45px 0 30px;
    ul {
      li {
        margin-right: 23px;
        margin-bottom: 14px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #161616;
        display: flex;
        cursor: pointer;
      }
      li:first-child {
        padding-left: 63px;
        cursor: default;
      }
    }
  }
}
.flex {
  display: flex;
}
</style>
