<!--  -->
<template>
  <div class="downLoadUrl" @click="$emit('download')" :class="{ 'is-baidu': isBaidu, 'is-360':is360, 'is-qihu': isQihu, 'is-qihu2': isQihu2 }" id="bd1">
    立即下载
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  props: {
    channel: null,
  },
  computed: {
    isBaidu() {
      return this.channel == 20210426043;
    },
    is360() {
      return this.channel == 15170001125;
    },
    isQihu() {
      return this.channel == 20200707006;
    },
    isQihu2() {
      return this.channel == 2887211655;
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.downLoadUrl {
  width: 286px;
  height: 64px;
  border-radius: 50px 50px 50px 50px;
  background: url("../../assets/images/bd01/downLoad_1.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: url("../../assets/images/bd01/downLoad_2.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>