<template>
  <header class="header" :class="[scrollTop > 0?'active':'']">
    <img class="header-logo" src="../../assets/images/logo3.png" alt="" @click="goback" />
    <div class="spacer"></div>
    <ul class="header-menu">
      <li class="header-menu-item" :class="{
            active: isAbout,
          }" v-for="(item, index) in menu" :key="index" @click="onMenuClick(item)">
        {{ item.name == "首页" && disableTitle ? "" : item.name }}
      </li>
    </ul>
    <a title="立即下载" :class="['downapp', {'is-baidu': isBaidu}, {'is-qihu': isQihu}, {'is-360': is360}]" @click="$emit('download')" id="bd1">
      <img src="../../assets/images/download2.png" alt="" />
      立即下载
    </a>
  </header>
</template>
  
  <script>
export default {
  props: {
    channel: {
      type: null,
      default: 0,
    },
    disableTitle: {
      type: Boolean,
      default: false,
    },
    isAbout: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    self: null,
    scrollTop: 0,
    menu: [
      {
        id: 2,
        name: "关于我们",
        url: "about",
      },
    ],
  }),
  computed: {
    isBaidu() {
      return this.channel == 20210426043;
    },
    is360() {
      return this.channel == 15170001125;
    },
    isQihu() {
      return this.channel == 20200707006;
    },
  },
  mounted() {
    // 添加事件监听
    console.log("20313");
    document.getElementById('bd01').addEventListener("scroll", this.handleBodyScroll);
  },

  beforeDestroy() {
    // 移除事件监听
    document.getElementById('bd01').removeEventListener("scroll", this.handleBodyScroll);
  },
  methods: {
    //this.$emit('download')
    onMenuClick(item) {
      location.href = `/${item.url}`;
    },
    goback() {
      location.href = `/`;
    },
    handleBodyScroll(event) {
      this.scrollTop = document.getElementById('bd01').scrollTop
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  height: 90px;
  font-family: "Microsoft YaHei";
  padding: 0 24px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  &.active {
    background: rgba(0, 0, 0, 0.7);
  }
  .header-title {
    margin: 0 24px;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 3px;
  }
}
.spacer {
  flex: 1;
}
.header-menu {
  display: flex;
  align-items: center;
  .header-menu-item {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    padding: 0 49px;
    cursor: pointer;
    position: relative;
    &.active::after {
      content: " ";
      display: block;
      position: absolute;
      width: 32px;
      height: 6px;
      border-radius: 5px;
      background: #020202;
      bottom: -28px;
      left: 50%;
      margin-left: -16px;
    }
  }
}
.downapp {
  width: 154px;
  height: 44px;
  background: url("../../assets/images/h_d.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  left: 310px;
  top: 378px;
  cursor: pointer;
  img {
    width: 26px;
    height: 26px;
    margin: 0 4px 0 0;
    display: block;
    float: left;
  }
  &:hover {
    background: url("../../assets/images/h_d_h.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
  