<!--  -->
<template>
  <div class="swiperC">
    <div class="title">
      <span>电脑主题美化</span>
    </div>
    <swiper :options="swiperOption" class="swiperCon" ref="swiper1" id="swiperConLR">
      <swiper-slide>
        <img src="../../assets/images/bd01/center/c_4.jpg" />
      </swiper-slide>
      <swiper-slide>
        <img src="../../assets/images/bd01/center/c_5.jpg" />
      </swiper-slide>
      <swiper-slide>
        <img src="../../assets/images/bd01/center/c_1.jpg" />
      </swiper-slide>
      <swiper-slide>
        <img src="../../assets/images/bd01/center/c_2.jpg" />
      </swiper-slide>
      <swiper-slide>
        <img src="../../assets/images/bd01/center/c_3.jpg" />
      </swiper-slide>
    </swiper>
    <div class="bottmSwiper">
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
      <download @download="onDownload" :channel="channel" class="down"></download>
    </div>
    <div class="btnBg">
      <img src="../../assets/images/bd01/leftBg.png" class="bgleft">
      <img src="../../assets/images/bd01/rightBg.png" class="bgright">
    </div>

  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import download from "./downLoadBtn.vue";
import "swiper/dist/css/swiper.css";
export default {
  name: "",
  components: {
    swiper,
    swiperSlide,
    download,
  },
  props: {
    channel: null,
  },
  data() {
    let self = this;
    return {
      slideW: 924,
      radius: (924 * 0.5) / Math.sin(Math.PI / 16),
      swiperOption: {
        effect: "coverflow",
        watchSlidesProgress: true,
        slidesPerView: "auto",
        loop: true,
        loopedSlides: 1,
        centeredSlides: true,
        grabCursor: true,
        loopAdditionalSlides: 999,
        slideToClickedSlide: true,
        touchRatio: 0, // 触摸系数为0，即不能拖动
        observer: true, // 当改变swiper的可观察项时，自动初始化swiper
        observeParents: true, // 当改变swiper的父元素时，自动初始化swiper
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        coverflow: {
          rotate: 50,
          stretch: -50,
          depth: 40,
          modifier: 0.8,
          slideShadows: !0,
        },
      },
    };
  },
  computed: {},
  methods: {
    onDownload() {
      this.$emit("download");
    },
  },
};
</script>

<style lang='scss' scoped>
.swiperC {
  width: 100%;
  height: 820px;
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  display: inline-block;
  .btnBg {
    top: 36px;
    position: absolute;
    width: 1920px;
    left: 50%;
    margin-left: -960px;
    z-index: 9;
    pointer-events: none;
    .bgleft {
      position: absolute;
      left: -446px;
      top: 0;
      width: 924px;
      height: 770px;
    }
    .bgright {
      position: absolute;
      right: -446px;
      top: 0;
      width: 924px;
      height: 770px;
    }
  }
  .swiperCon {
    width: 1920px;
    position: absolute;
    top: 143px;
    left: 50%;
    margin-left: -960px;
    height: 545px;
    padding-top: 5px;
    touch-action: pan-y;
    perspective: 1920px;
    cursor: default !important;
    ::v-deep(.swiper-wrapper) {
      transform-style: preserve-3d;
      transition-property: transform;
    }
    .swiper-slide {
      width: 924px;
      height: 520px;
      transform-style: preserve-3d;
      position: relative;
      transition-property: transform;
      flex-shrink: 0;
      cursor: pointer;
      border-radius: 20px;
      overflow: hidden;
      transform-origin: 50% 50%;
      margin-top: 8px;
      &.swiper-slide-active {
        border: 2px solid transparent;
        margin-top: 0px;
        &::before{
          background: url('../../assets/images/bd01/center/c_bg.png');
          content: '';
          position: absolute;
          left: -1px;
          right: -1px;
          bottom: -1px;
          top: -1px;
          z-index: 9;
          pointer-events: none;
          background-size: 100% 100%;
        }
      }
      img {
        border-radius: 20px;
        width: 924px;
        height: 520px;
        object-fit: cover;
      }
    }
  }
  .title {
    position: absolute;
    left: 50%;
    margin-left: -162px;
    width: 325px;
    height: 116px;
    background: url("../../assets/images/bd01/upupoo.png") no-repeat center
      center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 36px;
    color: #ffffff;
    &::before {
      content: "";
      position: absolute;
      left: 69px;
      bottom: 12px;
      width: 180px;
      height: 34px;
      background: url("../../assets/images/bd01/upupoo_b.png") no-repeat;
      background-size: 100% 100%;
    }
    span {
      position: absolute;
      z-index: 9;
      font-weight: 700;
      font-size: 36px;
      color: #ffffff;
      width: 216px;
      height: 58px;
      left: 50px;
      top: 40px;
    }
  }
  .bottmSwiper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 720px;
    height: 64px;
    width: 924px;
    left: 50%;
    margin-left: -462px;
    .swiper-button-next {
      width: 40px;
      height: 40px;
      right: 215px;
      bottom: 12px;
      margin: 0;
      background: url("../../assets/images/bd01/right.png") no-repeat;
      background-size: 40px 40px;
      top: 12px;
      &:hover {
        background: url("../../assets/images/bd01/right_h.png") no-repeat;
        background-size: 40px 40px;
      }
    }
    .swiper-button-prev {
      width: 40px;
      height: 40px;
      left: 215px;
      bottom: 12px;
      margin: 0;
      background: url("../../assets/images/bd01/left.png") no-repeat;
      background-size: 40px 40px;
      top: 12px;
      &:hover {
        background: url("../../assets/images/bd01/left_h.png") no-repeat;
        background-size: 40px 40px;
      }
    }
    .down {
    }
  }
}
</style>