<!--  -->
<template>
  <div class="wallpaperCon">
    <div class="wBox" ref="wBox" @mousemove="moduleAnimate($event)" @mouseleave="moduleAnimateReset($event)" :style="`transform: perspective(${perspective}px) rotateX(${rotateX}deg) rotateY(${rotateY}deg);`">
      <div class="move-wrapper overflow-hidden">
        <div class="move-circle" ref="moveCircle" :style="`transform: translate(${moveX}px, ${moveY}px);`"></div>
      </div>
      <div class="wallpaper">
        <div class="title">
          <span>桌面工具组件</span>
        </div>
        <div class="toolList">
          <div :class="`img_${index + 1}`" v-for="(item,index) in videoList" @mouseenter="mouseenterVideo(index)" @mouseleave="mouseleaveVideo(index)" :ref="`video_${index + 1}`" :key="index">
            <video :src="item.videoUrl" :poster="item.imgUrl" muted="muted" loop="loop"></video>
            <span>{{ item.name }}</span>
          </div>
        </div>
        <download @download="onDownload" :channel="channel" class="down"></download>
      </div>
    </div>

  </div>

</template>
  
  <script>
import download from "./downLoadBtn.vue";
export default {
  name: "",
  components: {
    download,
  },
  props: {
    channel: null,
  },
  data() {
    return {
      perspective: 1600,
      rotateX: 0,
      rotateY: 0,
      moveX: -600,
      moveY: 0,
      activeTagId: 0,
      wallpaperListPage: null,
      videoM: null,
      videoList: [
        {
          videoUrl: require("../../assets/images/bd01/tool/1.mp4"),
          imgUrl: require("../../assets/images/bd01/tool/1.jpg"),
          name: "屏保",
        },
        {
          videoUrl: require("../../assets/images/bd01/tool/2.mp4"),
          imgUrl: require("../../assets/images/bd01/tool/2.jpg"),
          name: "音乐频谱",
        },
        {
          videoUrl: require("../../assets/images/bd01/tool/3.mp4"),
          imgUrl: require("../../assets/images/bd01/tool/3.jpg"),
          name: "任务栏优化",
        },
        {
          videoUrl: require("../../assets/images/bd01/tool/4.mp4"),
          imgUrl: require("../../assets/images/bd01/tool/4.jpg"),
          name: "待办清单",
        },
      ],
    };
  },
  computed: {
    isBaidu() {
      return this.channel == 20210426043;
    },
    is360() {
      return this.channel == 15170001125;
    },
    isQihu() {
      return this.channel == 20200707006;
    },
    isQihu2() {
      return this.channel == 2887211655;
    },
  },
  created() {},
  methods: {
    onDownload() {
      this.$emit("download");
    },
    mouseenterVideo(index) {
      const el = this.$el;
      const videos = el.querySelectorAll(".toolList video");
      videos.forEach((item) => item.pause());
      videos[index].currentTime = 0;
      videos[index].play();
    },
    mouseleaveVideo(index) {
      const el = this.$el;
      const videos = el.querySelectorAll(".toolList video");
      videos.forEach((item) => item.pause());
      videos[index].currentTime = 0;
    },
    moduleAnimate(e) {
      var $moveCircle = this.$refs.moveCircle;
      var $wBox = this.$refs.wBox;
      var moveX = 0;
      var moveY = 0;
      var skewX = 0;
      var skewY = 0;
      moveX =
        e.clientX -
        $wBox.getBoundingClientRect().left -
        $moveCircle.clientWidth / 2;
      moveY =
        e.clientY -
        $wBox.getBoundingClientRect().top -
        $moveCircle.clientHeight / 2;
      skewX =
        ((e.clientY -
          $wBox.getBoundingClientRect().top -
          $wBox.clientHeight / 2) /
          $wBox.clientHeight) *
        2;
      skewY =
        -(
          (e.clientX -
            $wBox.getBoundingClientRect().left -
            $wBox.clientWidth / 2) /
          $wBox.clientWidth
        ) * 3;
      this.rotateX = skewX;
      this.rotateY = skewY;
      this.moveX = moveX;
      this.moveY = moveY;
      //   e.target.style.transform = `perspective(${elWidth}px) rotateX(${x}deg) rotateY(${y}deg) scale3d(1,1,1)`;
    },
    moduleAnimateReset(e) {
      this.rotateX = 0;
      this.rotateY = 0;
      this.moveX = -600;
      this.moveY = 0;
      // console.log("moduleAnimateReset");
      //   e.target.style.transform = "perspective(500px) rotateX(0) rotateY(0)";
    },
  },
};
</script>
  
  <style lang='scss' scoped>
.wallpaperCon {
  width: 1600px;
  height: 1040px;
  margin-top: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  z-index: 9;
  .wBox {
    width: 1600px;
    height: 1040px;
    transition: transform 0.3s ease-out;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 20px 20px 20px 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
    // transform-style: preserve-3d;
  }
  .move-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 99;
    .move-circle {
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 1.2s;
      mix-blend-mode: soft-light;
      will-change: transform;
      pointer-events: none;
      opacity: 0;
      border-radius: 50%;
      background: #fb38fe;
      width: 256px;
      height: 256px;
      filter: blur(153px);
      opacity: 0.8;
    }
  }
}
.wallpaper {
  // width: 1600px;
  // height: 1040px;
  // background: rgba(255, 255, 255, 0.06);
  // border-radius: 20px 20px 20px 20px;
  // border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 99;
  display: flex;
  flex-flow: wrap;
  .title {
    position: absolute;
    left: 50%;
    margin-left: -162px;
    width: 325px;
    height: 116px;
    background: url("../../assets/images/bd01/upupoo.png") no-repeat center
      center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 36px;
    color: #ffffff;
    margin-top: 24px;
    &::before {
      content: "";
      position: absolute;
      left: 69px;
      bottom: 12px;
      width: 180px;
      height: 34px;
      background: url("../../assets/images/bd01/upupoo_b.png") no-repeat;
      background-size: 100% 100%;
    }
    span {
      position: absolute;
      z-index: 9;
      font-weight: 700;
      font-size: 36px;
      color: #ffffff;
      width: 216px;
      height: 58px;
      left: 50px;
      top: 40px;
    }
  }
  .toolList {
    width: 100%;
    padding: 0 200px;
    display: flex;
    flex-flow: wrap;
    margin-top: 166px;
    div {
      margin-right: 18px;
      margin-bottom: 18px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      &.img_1 {
        width: 676px;
        height: 350px;
        border-radius: 10px 10px 10px 10px;
      }
      &.img_2 {
        width: 506px;
        height: 350px;
        border-radius: 10px 10px 10px 10px;
      }
      &.img_3 {
        width: 506px;
        height: 350px;
        border-radius: 10px 10px 10px 10px;
      }
      &.img_4 {
        width: 676px;
        height: 350px;
        border-radius: 10px 10px 10px 10px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      span {
        position: absolute;
        z-index: 9;
        left: 0;
        bottom: 22px;
        height: 48px;
        right: 0;
        padding: 0 22px;
        font-weight: 700;
        font-size: 30px;
        color: #ffffff;
        display: flex;
        align-items: center;
      }
    }
  }

  .down {
    margin: 0 auto;
    margin-top: 26px;
  }
}
</style>